import {NavigationStart, Router, RouterModule, Routes} from '@angular/router';
import {SistemaService} from './services/sistema/sistema.service';
import {NgModule} from '@angular/core';
import {IsAuthenticatedGuard} from './core/guards/is-authenticated.guard';
import {AdminGuard} from './modules/bookingsv2/admin.guard';
import {Homev2Component} from './modules/homev2/homev2.component';

declare var $: any;

const routes: Routes = [
  {
    path: '',
    component: Homev2Component,
  },
  {
    path: 'error-404',
    loadChildren: () => import('./pages/error404/error404.module')
      .then(m => m.Error404Module),
  },
  {
    path: ':title/:lemaxId/b',
    loadChildren: () => import('./pages/more-details/more-details.module')
      .then(m => m.MoreDetailsModule),
  },
  {
    path: 'shop/:travel/:typeBrowseText/:browseText/:howLong/:budget/:themes/:recommended/:themeID/:onlyOnSpecialOffer/:searchText/:SortBy',
    loadChildren: () => import('./pages/shop/shop.module')
      .then(m => m.ShopModule),
  },
  {
    path: 'aadventure/:countryStyleTheme/:tourTitle/:tourID/:type',
    loadChildren: () => import('./pages/products/products.module')
      .then(m => m.ProductsModule),
  },
  {
    path: 'adventure/:countryStyleTheme/:product/:tourID/:type',
    loadChildren: () => import('./pages/productsv2/productsv2.module')
      .then(m => m.Productsv2Module),
  },
  {
    path: 'tailor-made',
    loadChildren: () => import('./pages/tailor-made/tailor-made.module')
      .then(m => m.TailorMadeModule),
  },
  {
    path: 'pdf',
    loadChildren: () => import('./pages/products/products.module')
      .then(m => m.ProductsModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module')
      .then(m => m.LoginModule),

  },
  {
    path: 'ibe',
    loadChildren: () => import('./pages/ibe/ibe.module')
      .then(m => m.IbeModule),
  },
  // {
  //   path: 'darwin/booking',
  //   loadChildren: () => import('./pages/booking/booking-page.module')
  //     .then(m => m.BookingPageModule),
  // },
  {
    path: 'darwin/booking',
    loadChildren: () => import('./pages/bookingv2/bookingv2.module')
      .then(m => m.Bookingv2Module),
    canActivate: [AdminGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module')
      .then(m => m.ContactUsModule),
  },
  {
    path: 'tailor-made-thank-you/:reservationID',
    loadChildren: () => import('./pages/thank-you-tailor/thank-you-tailor.module')
      .then(m => m.ThankYouTailorModule),
  },
  {
    path: 'checkout/:tourTitle/:tourID/:type/:durationDD',
    loadChildren: () => import('./pages/checkout/checkout.module')
      .then(m => m.CheckoutModule),
  },
  {
    path: 'checkout-thank-you/:tourTitle/:tourID/:type/:durationDD/:reservationID/:onRequest/:storeName/:priceFormatted/:coupon',
    loadChildren: () => import('./pages/checkout-thank-you/checkout-thank-you.module')
      .then(m => m.CheckoutThankYouModule),
  },
  {
    path: 'darwin',
    loadChildren: () => import('./pages/user-and-stores/user-and-stores.module').then(m => m.UserAndStoresModule),
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: ':title/:lemaxId/a',
    loadChildren: () => import('./pages/landig-travel-styles/landig-travel-styles.module')
      .then(m => m.LandigTravelStylesModule),
  },
  {
    path: ':permalink/:id',
    loadChildren: () => import('./pages/content-pages/content-pages.module')
      .then(m => m.ContentPagesModule),
  },
  {
    path: '',
    redirectTo: '', pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'error-404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
// tslint:disable-next-line:class-name
export class APP_ROUTING {

  constructor(private router: Router, private _sistemaService: SistemaService) {
    // let node = document.createElement('script');
    // node.src = "https://chat-assets.frontapp.com/next/chat.bundle.js";
    // node.type = 'text/javascript';
    // document.getElementsByTagName('head')[0].appendChild(node);


    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (val.url.indexOf('5/b') > -1 ||
          val.url.indexOf('6/b') > -1 ||
          val.url.indexOf('7/b') > -1 ||
          val.url.indexOf('325632541320/b') > -1 || val.url.indexOf('inclussions') > -1 || val.url.indexOf('pdf') > -1) {
          _sistemaService.footerHeaderVisible = false;
        } else {
          _sistemaService.footerHeaderVisible = true;
        }
        const sp: string[] = val.url.split('/');
        _sistemaService.urlAnterior = _sistemaService.urlActual;
        _sistemaService.urlActual = [];
        for (let i = 1; i < sp.length; i++) {
          _sistemaService.urlActual.push(sp[i]);
        }
        // hablitar scroll
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.onmousewheel = (document as any).onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
      }
    });
  }

  preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  }

}
