<ng-container>
  <div class="row-line main-div">
    <a class="card-main" href="https://bambatravel.com/Independent/3/a" target="_blank" style="text-decoration: none">
        <img src="assets/img/home-page/independent-trips.png" alt="Your Image" style="border-radius: 12px 0 0 12px;"
             class="image1">
      <div class="sub1">
          <h2 class="subhed"><strong>Independent Trips</strong></h2>
          <p class="subt">Preset one to three-week trips including transport, activities, and accommodation to travel
            Independently on a budget.</p>
      </div>
    </a>
    <a href="https://bambatravel.com/Travel-Passes/1/a" target="_blank" class="card-main" style="text-decoration: none">
      <div class="sub1" style="text-align: right;">
        <h2 class="subhed"><strong>Travel Passes</strong></h2>
        <p class="subt">Explore multiple destinations at your own pace with flexible transport and activity passes valid for up to one year.</p>
      </div>
      <img src="assets/img/home-page/travel-passes.png" alt="Your Image" style="border-radius: 0 12px 12px 0;" class="image1">
    </a>
  </div>
  <div class="row-line">
    <a class="card-main" href="https://bambatravel.com/Group-Adventures/2/a" target="_blank" style="text-decoration: none">
      <img src="assets/img/home-page/group-adventure.png" alt="Your Image" style="border-radius: 12px 0 0 12px;" class="image1">
      <div class="sub1">
        <h2 class="subhed"><strong>Small Group Adventures</strong></h2>
        <p class="subt">Join a 2-10 day small group tour while on your independent adventure for a curated and social experience.</p>
      </div>
    </a>
    <a class="card-main" href="https://bambatravel.com/Tailor-Made/4/a" target="_blank" style="text-decoration: none">
      <div class="sub1" style="text-align: right;">
        <h2 class="subhed"><strong>Tailor Made on a Budget</strong></h2>
        <p class="subt">Get a personalized and budget-friendly itinerary designed to meet your unique travel needs and interests.</p>
      </div>
      <img src="assets/img/home-page/tailor-made.png" alt="Your Image" style="border-radius: 0 12px 12px 0;" class="image1">
    </a>
  </div>
</ng-container>
